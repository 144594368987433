<template>
  <el-card shadow="never">
    <el-row style="margin-bottom: 22px">
      <el-input v-model="queryInfo.assetSn" class="input" placeholder="资产码" clearable></el-input>
      <el-switch
          style="margin-right: 10px"
          v-model="queryInfo.expired"
          active-text="查看已失效">
      </el-switch>
      <el-button type="primary" @click="currentChange(1)" :loading="loading">查询</el-button>
      <el-button type="primary" @click="dialogVisible = true">添加</el-button>
      <el-button type="primary" @click="impDialogVisible = true">批量导入</el-button>
    </el-row>

    <el-table :data="ngStoreInterceptRuleList" border style="width: 100%" stripe>
      <el-table-column align="center" prop="assetSn" label="资产码"></el-table-column>
      <el-table-column align="center" prop="targetStoreName" label="目标库区"></el-table-column>
      <el-table-column align="center" prop="createUserName" label="创建人" width="100"></el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间" width="160"></el-table-column>
      <el-table-column align="center" prop="lendOrderCode" label="借出单号" width="220"></el-table-column>
      <el-table-column align="center" prop="returnOrderCode" label="内部归还单号" width="170"></el-table-column>
      <el-table-column align="center" prop="expireTime" label="失效时间" width="160"></el-table-column>
      <el-table-column align="center" label="操作" width="100">
        <template slot-scope="scope">
          <el-button @click="deleteRule(scope.row)" type="danger" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="queryInfo.pageNo"></el-pagination>
    </div>
    <el-dialog title="添加不良品库区拦截规则" :visible.sync="dialogVisible" :close-on-click-modal="false" width="700px" :before-close="handleClose">
      <el-form v-model="formData" label-width="200px">
        <el-form-item label="资产码：">
          <el-input v-model.trim="formData.assetSn" placeholder="请输入资产码" style="width: 350px" clearable></el-input>
        </el-form-item>
        <el-form-item label="目标库区：">
          <el-select v-model="formData.targetStore" placeholder="请选择目标库区" style="width: 350px" filterable>
            <el-option
                v-for="item in storeTypeList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="addRule">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="导入不良品库区拦截规则" :visible.sync="impDialogVisible" :close-on-click-modal="false" width="800px" :before-close="impHandleClose">
      <div class="remark" style="margin: 0">
        <div>操作指南：</div>
        <div>1. 请先下载
          <el-link type="primary" href="template/ngStoreIntercept.xlsx">Excel模板</el-link>
        </div>
        <div>2. 按模板文件格式填写数据</div>
        <div>3. 确认文件内容无误后，上传文件</div>
        <div>4. 如上传文件内容有误，修改后重新上传即可覆盖上次数据</div>

        <el-upload class="upload-demo"
                   drag
                   :headers="uploadHeaders"
                   :on-success="uploadSuccess"
                   :on-progress="uploadProgress"
                   :before-upload="beforeUpload"
                   accept=".xlsx,.xls"
                   :action="uploadUrl">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传xlsx文件</div>
          <p slot="tip" v-if="loading">上传中</p>
          <p class="loadingThree" slot="tip" v-if="loading">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </p>
          <p slot="tip" :class="loadSuccess?'success':'error'">{{ success }}</p>
        </el-upload>

      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="impHandleClose">关闭</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
export default {
  name: "NgStoreInterceptRule",
  data() {
    return {
      queryInfo: {
        assetSn: '',
        expired: false,
        pageNo: 1
      },
      formData: {
        assetSn: '',
        targetStore: ''
      },
      ngStoreInterceptRuleList: [],
      dialogVisible: false,
      impDialogVisible: false,
      loading: false,
      total: 0,
      pageSize: 0,
      uploadUrl: '',
      uploadHeaders: {},
      success: '',
      loadSuccess: false,
    }
  },
  computed: {
    storeTypeList() {
      return this.$store.getters.getDictList('storeType') || [];
    },
  },
  created() {
    this.search();
    this.uploadUrl = this.$baseUrl + 'ngStoreIntercept/upload';
    this.uploadHeaders = {token: localStorage.getItem('token')};
  },
  methods: {
    async search() {
      this.loading = true;
      const {data: res} = await this.$axios.post('ngStoreIntercept/queryRule', this.queryInfo);
      this.loading = false;
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.ngStoreInterceptRuleList = res.data.records;
      this.total = res.data.total;
      this.pageSize = res.data.size;
    },
    deleteRule(row) {
      this.$confirm('确定删除吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post('ngStoreIntercept/deleteRule', row.id).then(response => {
          if (response.data.code !== 0) {
            return this.$message.error(response.data.message);
          }
          this.$message.success("删除成功");
          this.search();
        })
      })
    },
    async addRule() {
      if (!this.formData.assetSn) {
        return this.$message.error('资产码不能为空');
      }
      if (!this.formData.targetStore) {
        return this.$message.error('目标库区不能为空');
      }

      const {data: res} = await this.$axios.post('ngStoreIntercept/addRule', this.formData);
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.$message.success("添加成功");
      this.search();
      this.handleClose()
      this.formData.assetSn = ''
      this.formData.targetStore = ''
    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.search()
    },
    handleClose() {
      this.dialogVisible = false;
    },
    impHandleClose() {
      this.search()
      this.impDialogVisible = false;
    },
    beforeUpload() {
      this.success = ''
    },
    uploadSuccess: function (response) {
      this.success = response.message;
      if (response.code == 0) {
        this.loadSuccess = true;
      } else {
        this.loadSuccess = false;
      }
    },
    uploadProgress: function () {
      this.loadSuccess = false;
    },
  }
}
</script>

<style scoped>
.input {
  width: 300px;
  margin-right: 10px;
}

.remark {
  line-height: 30px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 20px;
  color: darkslategray;
}

.success {
  color: green;
  font-size: 18px;
  padding-right: 10px;
}

.error {
  color: red;
  font-size: 18px;
  padding-right: 10px;
}
</style>